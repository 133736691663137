import React from "react";

import async from "../components/Async";

import { Briefcase, Map, ShoppingCart, Sliders, Users } from "react-feather";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

//website components
const ComingSoon = async(() => import("../pages/pages/ComingSoon"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));

// Pages components
const Orders = async(() => import("../pages/pages/Orders"));
const Settings = async(() => import("../pages/pages/Settings"));
const RegisterStaff = async(() => import("../pages/pages/RegisterStaff"));

// Tables components
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));
const StaffTable = async(() => import("../pages/tables/StaffTable"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Landing

const websiteRoutes = {
  id: "ComingSoon",
  path: "/",
  component: ComingSoon,
  children: null,
};

export const websitePagesRoutes = [websiteRoutes];

const dashboardsRoutes = {
  id: "Home",
  path: "/dashboard",
  component: Default,
  header: "Menu",
  icon: <Sliders />,
  containsHome: true,
  children: null,
  guard: AuthGuard,
};

const pagesRoutes = {
  id: "Customers",
  path: "/dashboard/customers",
  icon: <Users />,
  children: [
    {
      path: "/dashboard/customers/add",
      name: "Add new Customer",
      component: Settings,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/customers/all",
      name: "View All",
      component: () => <AdvancedTable tableTitle={"Customers Table"} />,
      guard: AuthGuard,
    },
    // {
    //   path: "/pages/blank",
    //   name: "Blank Page",
    //   component: Blank,
    //   guard: AuthGuard,
    // },
  ],
  component: null,
  //guard: AuthGuard,
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  children: null,
  guard: AuthGuard,
};
const staffRoutes = {
  id: "Staff",
  path: "/dashboard/staff",
  icon: <Briefcase />,
  children: [
    {
      path: "/dashboard/staff/add",
      name: "Add Staff Member",
      component: RegisterStaff,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/staff/all",
      name: "View All",
      component: StaffTable,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Account",
  path: "/admin",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const deliveryRoutes = {
  id: "Delivery",
  path: "/dashboard/delivery",
  icon: <Map />,
  component: GoogleMaps,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  orderRoutes,
  deliveryRoutes,
  staffRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [dashboardLayoutRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  // projectsRoutes,
  orderRoutes,
  // invoiceRoutes,
  staffRoutes,
  deliveryRoutes,
  // chatRoutes,
  // calendarRoutes,
  // authRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];
