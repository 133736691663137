import axios from "../utils/axios";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/authenticate", {
        email: credentials.email,
        password: credentials.password,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function refreshLogin(token) {
  return new Promise((resolve, reject) => {
    if (!token || token === "undefined") {
      reject("Session expired");
      throw Error("Session expired");
    }
    axios
      .post("/api/auth/refresh-token", {
        token: token,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
